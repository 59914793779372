/* eslint-disable @typescript-eslint/no-explicit-any */
import { prePageCall } from '@marriott/mi-headless-utils';
import { GetServerSideProps } from 'next';

//This returns a component which can be rendered in any product specific [[page.tsx]]
//to return the page template(Head, main, sections) along with the required react Components mentioned in model.json and
// load the CSS and JS libs required by static components from AEM.
import { ResponsiveGrid } from '@adobe/aem-react-editable-components';
import { Layout, respGridUtil } from '@marriott/mi-headless-utils';
import { Types } from '@marriott/mi-ui-library';
import clsx from 'clsx';
import { getDeployedEnvType, getNextMiApolloClient } from '@marriott/mi-apollo-client-utils';
import { phoenixCobrandECMQuery } from '@marriott/mi-cobrand-graphql';
import { chaseBoundlessCode } from '@marriott/mi-cobrand-components';
import { logger } from '@marriott/mi-headless-utils';
import operationSignatures from '../_constants/operationSignatures.json';
import { envVarKeys } from '../_constants/envVarKeys';
const { log } = logger({})('credit-cards.tsx');

export default function CreditCardsLandingPage({ model, pagePath, isAuthorMode }) {
  const {
    metaNames,
    metaProperties,
    title,
    staticComponentClientLibsCSS,
    staticComponentClientLibsJS,
    gridCss,
    canonical,
    hreflangList,
  } = model;
  const respGridModel = respGridUtil(model?.cqItems?.root?.[':items']?.responsivegrid);
  return (
    <Layout
      metaNames={metaNames}
      metaProps={metaProperties}
      title={title}
      staticComponentClientLibsCSS={staticComponentClientLibsCSS}
      staticComponentClientLibsJS={staticComponentClientLibsJS}
      isAuthorMode={isAuthorMode}
      gridCss={gridCss}
      canonical={canonical}
      hreflangList={hreflangList}
    >
      <section>
        <div className={clsx(Types.Themes.Alternate)}>
          <ResponsiveGrid
            key={pagePath}
            {...respGridModel}
            model={respGridModel}
            pagePath={pagePath}
            itemPath="root/responsivegrid"
          />
        </div>
      </section>
    </Layout>
  );
}

const cobrandSpecificData = async (context, parallelCallsArr) => {
  const query = new URLSearchParams(context.query);
  let showError = false;
  let ecmUserStatus = false;
  let setEcmCookie = false;
  let removeEcmCookie = false;
  const token = parallelCallsArr?.sessionData?.cacheData?.data?.accessToken;
  const requestId = `${Date.now()}`;
  const deployedEnvType = getDeployedEnvType();
  let enableEcm = false;
  const customerID = parallelCallsArr?.sessionData?.cacheData?.data?.consumerID || '';

  log.debug(`rendererlog] ECM feature flag status ${parallelCallsArr?.getFeatureFlagData?.data?.enableECM}`);
  if (
    parallelCallsArr?.getFeatureFlagData?.data?.enableECM &&
    parallelCallsArr?.getFeatureFlagData?.data?.enableECM === 'true'
  ) {
    enableEcm = true;
  }

  try {
    log.debug(`[rendererlog] Customer ID ${customerID}`);
    log.debug(`[rendererlog] authStateToken ${token}`);
    log.debug(`[rendererlog] Deployment env ${deployedEnvType}`);
    if (customerID && token && enableEcm) {
      if (
        !context?.req?.cookies['ecmUserStatus'] ||
        (context?.req?.cookies['ecmUserStatus'] &&
          context?.req?.cookies['customerID'] &&
          customerID !== context?.req?.cookies['customerID'])
      ) {
        setEcmCookie = true;
        const MiApolloClient = getNextMiApolloClient(operationSignatures, deployedEnvType);
        const headers = {
          'x-request-id': requestId,
          'graphql-force-safelisting': 'true',
          'x-b3-traceId': requestId,
          'x-b3-spanId': requestId,
          authStateToken: token,
          Cookie: context?.req?.cookies,
        };
        const { data, error } = await MiApolloClient.query({
          query: phoenixCobrandECMQuery,
          fetchPolicy: 'network-only',
          errorPolicy: 'all',
          variables: {
            customerId: customerID,
          },
          context: {
            headers: headers,
          },
        });
        if (data) {
          log.debug(`[rendererlog] Response data from UXL call ${JSON.stringify(data)}`);
          if (data.customer?.loyaltyInformation?.programPartners?.coBrands?.length) {
            const cobrands = data.customer.loyaltyInformation.programPartners.coBrands;
            const userList = cobrands.filter(partnerObj => {
              if (partnerObj?.partner?.code && partnerObj?.partner?.code === chaseBoundlessCode) {
                return true;
              }
              return false;
            });
            ecmUserStatus = userList?.length ? true : false;
          }
        }
        if (error) {
          log.debug(`[rendererlog] Error occurred during UXL call ${JSON.stringify(error)}`);
        }
      }
    } else {
      removeEcmCookie = true;
    }
  } catch (err) {
    log.debug(`[rendererlog] Error occurred during UXL call ${JSON.stringify(err)}`);
  }

  if (query.get('error') && query.get('error') === 'true') {
    showError = true;
  }

  return {
    operationSignatures: [],
    NEXT_PUBLIC_FETCH_OFFERS_API_URL: process.env.NEXT_PUBLIC_FETCH_OFFERS_API_URL,
    nextPublicEnvKeys: [...Object.keys(envVarKeys)],
    showError,
    productSpecificSeoUrl: ecmUserStatus ? '/credit-cards?ecm=boundless' : null,
    ecmUserStatus,
    customerID,
    setEcmCookie,
    removeEcmCookie,
  };
};

const mockModel = async () => {
  if (process.env['NODE_ENV'] === 'development') {
    // nx-ignore-next-line
    const { creditCardsPageModel } = await import('../_localDevMocks/credit-cards-page.model'); //Load mock model dynamically only for dev mode
    return creditCardsPageModel;
  }
  return {};
};

//SSR Render
export const getServerSideProps: GetServerSideProps<any> = prePageCall(cobrandSpecificData, mockModel);
